<template>
  <div>
    <div class="top-banner">
      <div class="bg"></div>
      <div class="ban">
        <div class="title-big">American Delivery Service </div>
        <div class="title-small">
          <div>Experience hassle-free, timely delivery of your products.
          </div>
          <div>We allow you to add at most 200 packages in one order. </div>
        </div>
      </div>
    </div>
    <div class="cont">
      <div class="title1">Last-Mile Delivery Solutions</div>
      <div class="top-des">
        Last-mile delivery solutions refer to the transportation and delivery services that focus on the final leg of the
        supply chain, bringing products from a distribution center or a fulfillment center to the end customer's location.
        This phase of the delivery process is often considered the most critical and challenging, as it involves
        navigating through complex urban environments, addressing specific customer requirements, and ensuring timely and
        accurate delivery.
      </div>
      <div class="paiban">
        <div class="images">
          <img class="two-img" src="../../../assets/images/home/driver1.png" alt="">
          <img class="two-img" src="../../../assets/images/home/driver2.png" alt="">
        </div>
        <div class="content">
          <div class="title">Real-Time Tracking and Visibility</div>
          <div class="des">We understand the importance of transparency and visibility in last-mile delivery. Our advanced
            tracking technologies enable real-time tracking of deliveries, allowing you and your customers to monitor the
            progress and estimated arrival times. With live updates and notifications, you can proactively communicate
            with
            your customers and provide them with a seamless delivery experience.
          </div>
          <div class="des">This transparency enhances trust and customer satisfaction by keeping them informed about the
            status of their shipments.
          </div>
        </div>
      </div>
      <div class="paiban">
        <div style="margin-right:60px;">
          <div class="title">Advanced Routing and Optimization</div>
          <div class="des">
            Our last-mile delivery solutions utilize advanced routing algorithms and optimization techniques to determine
            the
            most efficient delivery routes. By considering factors such as traffic conditions, delivery time windows, and
            customer preferences, we can minimize travel distances, reduce fuel consumption, and ensure on-time
            deliveries.
            This optimization leads to cost savings and improved customer satisfaction.
          </div>
        </div>
        <div class="word">
          <img src="../../../assets/images/home/word.png" alt="">
        </div>
      </div>
      <div class="paiban">
        <div class="word">
          <img src="../../../assets/images/home/girl.png" alt="">
        </div>
        <div style="margin-left:60px;">
          <div class="title">Customer Communication and Support</div>
          <div class="des">
            Effective communication is essential for a positive last-mile delivery experience. Our solutions include
            robust
            customer communication channels, allowing you to provide timely updates, delivery notifications, and
            responsive
            support. By keeping your customers informed throughout the delivery process, you can enhance their
            satisfaction,
            build trust, and foster long-term relationships.
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.top-banner {
  box-sizing: border-box;
  position: relative;
  height: 538px;
  overflow: hidden;

  .bg {
    background-image: url('../../../assets/images/home/cangku12.png');
    background-size: cover;
    background-position: 50% 50%;
    height: 540px;
    filter: blur(2px);
  }

  .ban {
    width: 700px;
    position: absolute;
    z-index: 9;
    top: 50%;
    right: 50%;
    transform: translate(50%, -70%);
  }

  .title-big {
    font-weight: 500;
    font-size: 56px;
    color: #fff;
    text-align: center;
    line-height: 64px;
    padding-top: 166px;
  }

  .title-small {
    margin-top: 28px;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    line-height: 28px;
    color: #fff;
  }
}

.cont {
  width: 1300px;
  margin: 0 auto;
  padding: 60px 40px;
  box-sizing: border-box;
  line-height: 1.8;

  .top-des {
    font-size: 26px;
    font-weight: 400;
    color: var(--color-text-2);
    margin-bottom: 60px;
    margin-top: 60px;
  }

  .title {
    font-weight: 300;
    font-size: 33px;
    margin-bottom: 16px;
    color: #333;
  }

  .title1 {
    text-align: center;
    font-weight: 300;
    font-size: 56px;
    line-height: 53px;
    color: #333333;
  }

  .des {
    font-weight: 400;
    font-size: 22px;
    line-height: 1.8;
    color: #7D7D7D;
    padding-top: 10px;
  }

  .images {
    display: flex;
    justify-content: space-between;
    border: 1px solid var(--color-border);
    border-radius: 8px;
    padding: 2px;
  }

  .word {

    img {
      width: 560px;
      border-radius: 8px;
    }
  }
}

.paiban {
  display: flex;
  justify-content: space-between;
  margin-top: 100px;

  .images {
    margin-right: 60px;

    .two-img {
      width: 320px;
    }
  }
}
</style>
